.dropdownlabel {
  margin-bottom: 0.5em;
}
.dropdown {
  width: 100%;
  border-color: var(--primary);
}

@media (min-width: 640px) and (max-width: 800px) {
  .DropdownContainer {
    margin: 1em 16em 0em 1em;
    max-width: 150px;
  }
}
@media (min-width: 0px) and (max-width: 640px) {
  .DropdownContainer {
    margin: 1em 16em 0em 1em;
    max-width: 150px;
  }
}
