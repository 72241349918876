
.xlHeader {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;
}

.exceltable {
  border-collapse: collapse;
  width: 100%;
}

.exceltable th {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ccc; 
  border-right: 1px solid #ccc;
}

.exceltable td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ccc; 
  border-right: 1px solid #ccc;
}

.exceltable tr.even {
  background-color: #f2f2f2;
}

.exceltable tr.odd {
  background-color: #fff;
}

.loadMore {
  background-color: #1274AC; 
  color: white; 
  border: none; 
  padding: 10px 10px; 
  text-align: center; 
  text-decoration: none; 
  display: inline-block; 
  font-size: 14px;
  margin-top: 20px; 
  cursor: pointer;
  border-radius: 5px; 
}
