.k-window-actions {
  margin: 0px !important;
  margin-inline-end: -4px;
  line-height: 1;
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
  align-items: center;
  vertical-align: top;
}
