.loading {
  text-align: center;
  font-size: medium;
  /* margin:3em 9em 4.5em 5em; 8.3*/
  /* margin: 4em 9em 2em 5em; */
  /* min-width: 40px;
  max-width: 40px; */
}
.searchButton {
  margin-top: 28px;
  /* padding: 4px; */
  /* color: white; */
  /* background-color: ; */
  font-size: medium;
  padding: 6px !important;
}

@media (min-width: 640px) and (max-width: 800px) {
  .loading {
    margin: 3em 8em 0em 0em;
    min-width: 60px;
    max-width: 60px;
  }
}
