.navContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 3.5rem 0.5rem 3.5rem;
  background-color: #16013a;
  min-height: 70px;
}
.profileAvatar {
  font-size: 20px;
  font-weight: 500;
}

#navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

#navbar li {
  list-style: none;
  padding: 0 20px;
}

.navLink {
  color: var(--primary-light);
  font-size: 1.5rem;
  font-weight: bold;
  background-color: transparent;
  text-decoration: none;
 
}
.navigateOptions{
  display: none;
}
#logotext {
  border: none;
  color: #63afcd;
  background-color: #16013a;
  padding: 0px;
  font-size: 1.5rem;
}

a.navLink.active {
  box-sizing: border-box;
  color: white;
  border-radius: 4px;
  border: 1px solid #1274ac;
  background-color: #1274ac;
  padding: 4px 8px;
  transition: 0.5s ease-in-out;
}

.smallscreen {
  display: none;
}

@media screen and (max-width: 850px) {
  .avatar {
    display: none;
  }

  #navbar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: fixed;
    padding: 40px 0 0 10px;
    top: 7%;
    left: 100%;
    width: 100%;
    height: 100%;
    background-color: #16013a;
    z-index: 1000;
    transition: 0.3s ease-in-out;
  }

  #navbar.active {
    left: 0;
  }

  .smallscreen {
    display: block;
    align-items: center;
  }

  .smallscreen span {
    font-size: 25px;
  }

  #navbar li {
    position: relative;
    top: 30%;
    margin-bottom: 40px;
  }
}

/* .navContainer {
  display: flex;
  padding: 0.5rem 3.5rem 0.5rem 3.5rem;
  background-color: #16013a;
  width: 100%;
  min-height: 70px;
}
.navItems {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100% !important;
}
.navLinks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  
}

#logotext{
 
  border:none;
  color:#63AFCD;
  background-color: #16013a;
  padding: 0px;
  font-size: inherit;
  
}
a.navLink.active{
box-sizing: border-box;
color: white;
border-radius: 4px;
border: 1px solid #1274AC;
background-color:#1274AC ;
padding: 4px 8px;
transition: 0.5s ease-in-out;
  
}



.navLink {
  color: var(--primary-light);
  font-size: 1rem;
  font-weight: bold;
  background-color: transparent;
  text-decoration: none;
}

#toolbar {
  padding: 0;
}

#logo {
  display: none;
 
}

hr.divider {
  border-color: var(--primary-light);
  font-weight: bold;
  padding: 0 0.5em 0 0.5em;
}

@media (min-width: 601px) {
  #logo {
    display: block;
    flex-grow: 1;
    font-size: 1rem;
  }

}

@media (min-width: 901px) {
  #logo {
    display: block;
    flex-grow: 1;
    font-size: 1.5rem;
  }

  .navLink {
    color: var(--primary-light);
    font-size: 1.5rem;
    font-weight: bold;
  }
} */

/*.navContainer {
  display: flex;
  padding: 0.5rem 3.5rem 0.5rem 3.5rem;
  background-color: #16013a;
  width: 100%;
  min-height: 70px;
}
.navItems {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100% !important;
}
.navLinks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.navLink {
  color: var(--primary-light);
  font-size: 1rem;
  font-weight: bold;
  background-color: transparent;
  text-decoration: none;
}

#toolbar {
  padding: 0;
}

#logo {
  display: none;
}

hr.divider {
  border-color: var(--primary-light);
  font-weight: bold;
  padding: 0 0.5em 0 0.5em;
}

@media (min-width: 601px) {
  #logo {
    display: block;
    flex-grow: 1;
    font-size: 1rem;
  }
}

@media (min-width: 901px) {
  #logo {
    display: block;
    flex-grow: 1;
    font-size: 1.5rem;
  }

  .navLink {
    color: var(--primary-light);
    font-size: 1.5rem;
    font-weight: bold;
  }
}
*/
