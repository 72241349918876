.searchField {
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  border: 1px solid #2d9fd9;
  color: #a0d18c;
  width: 250px;
  height: 30px;
  padding-left: 10px;
}

.searchField:focus {
  outline: none;
  border: 1px solid #a0d18c;
  color: #2d9fd9;
}
.permissionFilter {
  display: flex;
  width: 100%;
  gap: 30px;
  align-items: center;
  justify-content: end;
}
