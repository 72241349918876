:root {
  --primary: #16013a;
  --primary-light: #63afcd;
  --secondary: #e1c28c;
  --secondary-light: #eae2d3;
  --file-icon: #66c2ff;
  --folder-icon: #cdaa42;
  --download-icon: #046704;
  font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
body {
  position: relative;
  background-image: radial-gradient(
    circle farthest-corner at 7.4% 45.1%,
    rgba(236, 206, 251, 1) 0%,
    rgba(205, 237, 246, 1) 45.1%,
    rgba(227, 251, 252, 1) 78.4%,
    rgba(230, 247, 235, 1) 90%
  );
}

html,
body {
  height: 100%;
}

my-app {
  display: block;
  width: 100%;
  height: 100%;
  overflow: auto;
  min-height: 80px;
  box-sizing: border-box;
  padding: 30px;
}

@keyframes k-loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.dowbloadandView {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 20px 40px 0px 40px;
}
.k-i-loading.k-example-loading {
  font-size: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgb(144, 152, 165);
}

.k-i-loading.k-example-loading::before,
.k-i-loading.k-example-loading::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  content: "";
  box-sizing: inherit;
  border-radius: 50%;
  border-width: 0.05em;
  border-style: solid;
  border-color: currentColor;
  border-top-color: transparent;
  border-bottom-color: transparent;
  background-color: transparent;
}
.k-icon.k-i-loading.k-example-loading::before,
.k-icon.k-i-loading::after {
  content: "";
}

.k-i-loading.k-example-loading::before {
  margin-top: -0.5em;
  margin-left: -0.5em;
  width: 1em;
  height: 1em;
  animation: k-loading-animation 0.7s linear infinite;
}

.k-i-loading.k-example-loading::after {
  margin-top: -0.25em;
  margin-left: -0.25em;
  width: 0.5em;
  height: 0.5em;
  animation: k-loading-animation reverse 1.4s linear infinite;
}

.example-wrapper {
  min-height: 280px;
  align-content: flex-start;
}
.example-wrapper p,
.example-col p {
  margin: 20px 0 10px;
}
.example-wrapper p:first-child,
.example-col p:first-child {
  margin-top: 0;
}
.example-col {
  display: inline-block;
  vertical-align: top;
  padding-right: 20px;
  padding-bottom: 20px;
}
.example-config {
  margin: 0 0 20px;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.event-log {
  margin: 0;
  padding: 0;
  max-height: 100px;
  overflow-y: auto;
  list-style-type: none;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background-color: white;
}
.event-log li {
  margin: 0;
  padding: 0.3em;
  line-height: 1.2em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
.event-log li:last-child {
  margin-bottom: -1px;
}
