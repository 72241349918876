.mainContainer {
  display: flex;
  padding: 1em 3.5em;
  height: 100%;
  align-items: center;
  /* background-color: var(--secondary-light); */
  flex-direction: column;
}
.setFileSearchImage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  transition: width 2s, height 4s;
  animation: hideMe 5s forwards;
}

.uploadFileLink {
  color: #116da2;
  cursor: pointer;
  text-decoration: underline;
}

.fileNameWithIcon {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 5px;
}
.selectors {
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin: 10px 0px 10px 0px;
  width: 100%;
  height: 59px;
  align-items: center;
  justify-content: center;
  animation: hideMe 2s forwards;
}

.top10 {
  /*   position: absolute;
  left:82%;
  top:45%;
  align-items: center;
  width: 200px; */
  align-items: center;
  margin: 4.4em -1em 2em 0em;
  width: 250px;
}

@media (min-width: 640px) and (max-width: 800px) {
  .selectors {
    flex-direction: column;
    gap: 5px 5px;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    min-height: 200px;
  }

  .top10 {
    margin: 2em 3em 0em 1em;
    max-height: 30px;
    min-height: 30px;
  }
}

@media (min-width: 00px) and (max-width: 640px) {
  .selectors {
    flex-direction: column;
    gap: 5px 5px;
    align-items: center;
    justify-content: center;
    max-width: 800px;
    min-height: 200px;
  }
  .top10 {
    margin: 2em 3em 0em 1em;
    max-height: 30px;
    min-height: 30px;
  }
}
.placeholderLabel {
  margin-top: 20px;
  font-size: 25px;
  font-weight: bold;
}
.fileExplorer {
  height: 70%;
  width: 100%;
  transition: width 12s, height 14s;
  animation: hideMe 2s forwards;
}

.mainContentAn {
  margin-top: 20%;
  width: 50%;
  height: auto;
  align-items: center;
  justify-content: center;
  display: flex;
}
.searchingText {
  font-size: 20px;
  font-weight: 400;
}
/* .mainContent {
  width: 100%;
} */
.animationstyle {
  width: 600px;
  height: 600px;
  padding: 50px;
  margin-left: 200px;
  margin-right: 200px;
}
.setRightAligned {
  display: flex;
  justify-content: start;
}
.mainContent > button {
  padding: 1em;
}

.searchIcon {
  font-weight: bold;
}

hr#divider {
  border-color: var(--primary);
}

#searchPlaceholder {
  margin-top: 3em;
  margin-left: 4em;
  margin-right: 4em;
  width: 15rem;
}

label.placeholderLabel {
  margin-top: 1em;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 300;
}

.filesection {
  display: flex;
  flex: 0.7;
}

@media (min-width: 769px) {
  #searchPlaceholder {
    width: 20rem;
  }

  #searchPlaceholder {
    margin-left: 5em;
  }
}

@media (min-width: 901px) {
  .mainContainer {
    flex-direction: column;
    height: 100vh;
  }

  #searchPlaceholder {
    margin-left: 10em;
  }
}

@media (min-width: 1200px) {
  #searchPlaceholder {
    width: 32rem;
  }
}

@keyframes hideMe {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* .mainContainer {
  display: flex;
  padding: 1em 3.5em;
  height: 100%;
  align-items: center;
  background-color: var(--secondary-light);
  flex-direction: column;
}
.setRightAligned {
  display: flex;
  justify-content: start;
}
.top10 {
  margin-top: 20px;
}
.mainContentAn {
  width: 50%;
}
.mainContent {
  /* width: 100%;
}
/*
.animationstyle {
  width: 600px;
  height: 600px;
  padding: 50px;
  margin-left: 200px;
}
.mainContent > button {
  padding: 1em;
}

.searchIcon {
  font-weight: bold;
}

hr#divider {
  border-color: var(--primary);
}

#searchPlaceholder {
  margin-top: 5em;

  width: 20rem;
}

label.placeholderLabel {
  margin-top: 5em;
  margin-left: 5em;
  text-align: center;
  font-size: 1.3rem;
}

.selectors {
  display: flex;
  flex: 0.3;
  flex-direction: column;
}
.filesection {
  display: flex;
  flex: 0.7;
}

@media (min-width: 769px) {
  #searchPlaceholder {
    width: 25rem;
  }

  #searchPlaceholder {
    margin-left: 5em;
  }
}

@media (min-width: 901px) {
  .mainContainer {
    flex-direction: row;
    height: 100vh;
  }

  #searchPlaceholder {
    margin-left: 10em;
  }
}

@media (min-width: 1200px) {
  #searchPlaceholder {
    width: 32rem;
  }
} */
