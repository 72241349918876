.editpopup {
  padding: 10px;
  margin: 10px;
  border-radius: 40px;
}
.popupTitle {
}

.k-input {
  padding: 10px 5px !important;
}
.popupInputLable {
  padding: 10px 0px;
}

.k-checkbox-md {
  width: 20px !important;
  height: 20px !important;
}
.k-checkbox {
  margin-right: 10px !important;
  border-color: rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
}

.sumbmitButtons {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 20px;
}
.k-column-title {
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 1;
  overflow: hidden;

  font-weight: 500;
}
