.spinnerContainer {
  width: 12vmax;
  height: 12vmax;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -8vmax; /* Adjust the margin-top value as needed */
}

.divSpinner {
  width: 100%;
  height: 100%;
  border-right: 16px solid #16013a; /* Increase the border size for a larger spinner */
  border-radius: 100%;
  animation: spinRight 800ms linear infinite;
}

.loadingText {
  text-align: center;
  font-weight: 800;
  color: #63afcd;
  font-size: 18px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
 
}

.loadingText.no-animation {
  animation: none;
}

/* Rest of the CSS styles for the spinner animation */

.divSpinner:before,
.divSpinner:after {
  content: "";
  width: 9vmax; /* Increase the size for a larger spinner */
  height: 9vmax; /* Increase the size for a larger spinner */
  display: block;
  position: absolute;
  top: calc(50% - 5vmax); /* Adjust the position as needed */
  left: calc(50% - 5vmax); /* Adjust the position as needed */
  border-left: 5px solid #16013a; /* Increase the border size for a larger spinner */
  border-radius: 100%;
  animation: spinLeft 800ms linear infinite;
}



@keyframes spinLeft {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(720deg);
  }
}

@keyframes spinRight {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}




/* @import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");

.searchContent {
  position: relative;
}

.searchContent h2 {
  color: #fff;
  font-size: 8em;
  position: absolute;
  transform: translate(-50%, -50%);
}

.searchContent h2:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 2px #03a9f4;
}

.searchContent h2:nth-child(2) {
  color: #03a9f4;
  animation: animate 4s ease-in-out infinite;
}

@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      16% 44%,
      33% 50%,
      54% 60%,
      70% 61%,
      84% 59%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }

  50% {
    clip-path: polygon(
      0% 60%,
      15% 65%,
      34% 66%,
      51% 62%,
      67% 50%,
      84% 45%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }
}
 */