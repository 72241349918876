#pdf-download {
  display: none !important;
}

.overlay__background {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 9;
}

.overlay__container {
  background-color: white;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  z-index: 10;
  padding: 30px;
  width: 70%;
  height: 70%;
  overflow-x: scroll;
  overflow-y: scroll;
}

.overlay__controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.overlay__close:after {
  display: inline-block;
  content: "\00d7";
}

.overlay__close {
  border: none;
  background-color: transparent;
  font-size: 36px;
  cursor: pointer;
}
